import { Component } from "react"
import "./css/Navigation.css"
import { NavLink } from "react-router-dom"
import { DropdownButton, Dropdown } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveButton from "./ResponsiveButton"
import { withTranslation } from 'react-i18next'
import i18n from "i18next"
import "../../i18n"



class Navigation extends Component {

    state = {
        allLanguages: ["English", "Français"],
    }


    //Arrow fx for binding
    handleLanguageSelected = index => {
        const { allLanguages } = this.state
        i18n.changeLanguage(allLanguages[index].substring(0, 2).toLowerCase());
        console.log("Switched to " + allLanguages[index])

    }

    render() {
        const { allLanguages } = this.state
        const { t } = this.props
        return (
            <header>
                <div className="nav-content">
                    <NavLink exact to="/">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="nav-logo" id="nav-logo" width={120} height={110} />
                    </NavLink>
                    <ul>
                        <NavLink className="navlink " exact activeClassName="current" to="/">
                            <li className="navlink link-underline">{t("nav.nav-home")}</li>
                        </NavLink>

                        <NavLink className="navlink" exact activeClassName="current" to="/Downloads">
                            <li className="navlink  link-underline">{t("nav.downloads")}</li>
                        </NavLink>

                        <a href="https://github.com/dd060606/OpenPasswordManager" rel="noopener noreferrer" target="_blank"><li className="external-link link-underline">GitHub</li></a>
                    </ul>
                    <div>
                        <a href="https://opm-app.dd06-dev.fr/#/auth/login" className="my-password-manager-btn">{t("nav.my-password-manager")}</a>
                        <DropdownButton id="language-selector-btn" title={<i className="fas fa-globe fa-2x" />}>
                            {allLanguages.map((language, index) => (
                                <Dropdown.Item onClick={() => this.handleLanguageSelected(index)} key={index}>{language}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <ResponsiveButton />
                    </div>


                </div>

            </header >)
    }
}

export default withTranslation()(Navigation)