import { Component } from "react"
import "./css/Downloads.css"
import { withTranslation } from "react-i18next"
import "../i18n"
import axios from "axios"

class Downloads extends Component {

    state = {
        windowsUrl: "",
        linuxUrl: "",
        macosUrl: ""
    }

    componentDidMount() {
        axios.get("https://api.github.com/repos/dd060606/OpenPasswordManager/releases/latest")
            .then(res => {
                for (let i = 0; i < res.data.assets.length; i++) {
                    if (res.data.assets[i].name.endsWith(".exe")) {
                        this.setState({ windowsUrl: res.data.assets[i].browser_download_url })
                    }
                    else if (res.data.assets[i].name.endsWith(".AppImage")) {
                        this.setState({ linuxUrl: res.data.assets[i].browser_download_url })
                    }
                    else if (res.data.assets[i].name.endsWith("x64.dmg")) {
                        this.setState({ macosUrl: res.data.assets[i].browser_download_url })
                    }
                }
            })
            .catch(err => {
                console.error(err.message)
            })
    }

    render() {
        const { windowsUrl, linuxUrl, macosUrl } = this.state
        const { t } = this.props
        return (
            <div className="downloads">
                <h1>{t("nav.downloads")}</h1>
                <h3>{t("downloads.desktop-apps")}</h3>

                <div className="platforms">
                    <div className="platform">
                        <p className="platform-name">Windows</p>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/windows.png`} className="platform-img" alt="windows" width={100} />
                        <a href={windowsUrl} className="download-btn">{t("downloads.download")}</a>

                    </div>
                    <div className="platform">
                        <p className="platform-name">Linux</p>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/linux.png`} className="platform-img" alt="linux" width={100} />
                        <a href={linuxUrl} className="download-btn">{t("downloads.download")}</a>

                    </div>
                    <div className="platform">
                        <p className="platform-name">MacOS</p>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/macos.png`} className="platform-img" alt="macos" width={100} />
                        <a href={macosUrl} className="download-btn">{t("downloads.download")}</a>
                    </div>
                </div>

                <a href="https://github.com/dd060606/OpenPasswordManager/releases" rel="noopener noreferrer" target="_blank" className="all-releases">{t("downloads.all-releases")}</a>
            </div>
        )
    }
}



export default withTranslation()(Downloads)
