import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './components/Home';
import Downloads from './components/Downloads';
import Error404 from './components/errors/Error404';
import Footer from "./components/Footer"


import Navigation from "./components/nav/Navigation"
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie';
const cookies = new Cookies();



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navigation />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/Downloads" exact component={Downloads} />
        <Route path="/" component={Error404} />
      </Switch>
      <Footer />
    </Router>
  </React.StrictMode >,
  document.getElementById('root')
)


export { cookies }
