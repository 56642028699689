import React from 'react';
import "./css/ResponsiveNav.css"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NavLink } from "react-router-dom"
import { setMenuOpen } from "./ResponsiveButton"
import { withTranslation, useTranslation } from 'react-i18next'
import "../../i18n"


const ResponsiveNav = ({ open }) => {
  const isResponsive = useMediaQuery('(max-width: 1030px)');

  const { t } = useTranslation()
  return (
    <ul className="responsive-nav-ul" open={open} style={{ transform: open ? 'translateX(0)' : 'translateX(100%)', display: isResponsive ? "flex" : "none" }}>
      <NavLink className="responsive-navlink " exact activeClassName="current" to="/" onClick={() => setMenuOpen(false)}>
        <li className="responsive-navlink"><i className="fad fa-home"></i> {t("nav.nav-home")}</li>
      </NavLink>

      <NavLink className="responsive-navlink" exact activeClassName="current" to="/Downloads" onClick={() => setMenuOpen(false)}>
        <li className="responsive-navlink"><i className="fad fa-download"></i> {t("nav.downloads")}</li>
      </NavLink>

      <a href="https://github.com/dd060606/OpenPasswordManager" rel="noopener noreferrer" className="responsive-navlink" target="_blank"><li className="responsive-navlink"><i className="fab fa-github"></i> GitHub</li></a>

      <a href="https://opm-app.dd06-dev.fr/#/auth/login"> <li className="responsive-navlink"><i className="fad fa-lock-alt"></i> {t("nav.my-password-manager")}</li></a>


    </ul>
  )
}

export default withTranslation()(ResponsiveNav)