import './css/Home.css';
import { useTranslation, withTranslation } from 'react-i18next'
import "../i18n"

function Home() {

  const { t } = useTranslation()
  return (
    <div className="home">
      <div className="home-header">
        <h1>OpenPasswordManager</h1>
        <img src={`${process.env.PUBLIC_URL}/assets/images/icon.png`} className="icon" alt="icon" width={50} />
        <h4 className="slogan">{t("home.slogan")}</h4>
        <a className="get-started-button" href="https://opm-app.dd06-dev.fr/#/auth/signup">{t("home.get-started")}</a>
      </div>
      <section className="features-section">
        <h4 className="features-title">{t("home.features")}</h4>

        <div className="features">
          <div className="feature">
            <i className="fad fa-browser feature-icon"></i>
            <div className="feature-info">
              <p className="feature-title">{t("home.feature-webapp-title")}</p>
              <p className="feature-desc">{t("home.feature-webapp-desc")}</p>

            </div>
          </div>
          <div className="feature">
            <i className="fad fa-desktop feature-icon"></i>

            <div className="feature-info">
              <p className="feature-title">{t("home.feature-desktopapps-title")}</p>
              <p className="feature-desc">{t("home.feature-desktopapps-desc")}</p>

            </div>
          </div>
          <div className="feature">
            <i className="fad fa-server feature-icon"></i>

            <div className="feature-info">
              <p className="feature-title">{t("home.feature-db-store-title")}</p>
              <p className="feature-desc">{t("home.feature-db-store-desc")}</p>

            </div>

          </div>
          <div className="feature">
            <i className="fad fa-lock-alt feature-icon"></i>

            <div className="feature-info">
              <p className="feature-title">{t("home.feature-password-title")}</p>
              <p className="feature-desc">{t("home.feature-password-desc")}</p>

            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

export default withTranslation()(Home);
