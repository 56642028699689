import { Component } from "react"
import "./css/Footer.css"

import { withTranslation } from 'react-i18next'
import "../i18n"

class Footer extends Component {


    render() {
        const { t } = this.props
        return (
            <footer>
                <a href="https://github.com/dd060606/OpenPasswordManager" rel="noopener noreferrer" target="_blank"><i className="fab fa-github" /> GitHub</a>
                <p >{t("footer.created-by")} <strong><a href="https://github.com/dd060606" rel="noopener noreferrer" target="_blank">dd_06</a></strong></p>
            </footer >)
    }
}

export default withTranslation()(Footer)